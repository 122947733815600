import { useEffect, useState } from "react";
import { useContext } from 'react';
import { LanguageContext } from '../Components/LanguageContext';
import handleTransformLang from '../functions/handleTransformLang'
import DownloadaPageData from '../lang/pages/download.json'

export default function ({ date }) {
    const { language } = useContext(LanguageContext);

    const [remainingTime, setRemainingTime] = useState(null);
    const [content, setContent] = useState(handleTransformLang(DownloadaPageData.download));

    useEffect(() => {

        const endTime = new Date(date).getTime();
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = endTime - now;

            if (distance <= 0) {
                clearInterval(interval);
                setRemainingTime('Time Expired');
            } else {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setRemainingTime({
                    "days": days,
                    "hours": hours,
                    "minutes": minutes,
                    "seconds": seconds
                });

            }

        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        setContent(handleTransformLang(DownloadaPageData.download))
    }, [language])

    return (
        <>
            {remainingTime !== null && (
                <p className="text-sky-500 dark:text-blue-700 text-lg">

                    {`
                ${remainingTime.days} ${content.day} ${remainingTime.hours} 
                ${content.hour} ${remainingTime.minutes} ${content.minute} 
                ${remainingTime.seconds} ${content.second}
                `}
                </p>
            )}
        </>
    )
}