
export default function Video({ youtube }) {
    console.log("video")
    return (
        <iframe
            className="object-contain w-full h-full"
            src={Loading(youtube)}
            title="youtube"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
        >
        </iframe>
    )
}
function Loading(youtube) {
    const videourl = handleVideoPath(youtube)

    function handleVideoPath(youtube) {
        function getId(youtube) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = youtube.match(regExp);
            return (match && match[2].length === 11)
                ? match[2]
                : null;
        }
        return ('https://www.youtube.com/embed/' + getId(youtube) + "?autoplay=0&rel=0&controls=1&loop=1&mute=0")

    }

    return videourl;
}
