import languageMappings from "../lang/languages.json"

export default function handleLangSwitch(lang) {
    let selectedLanguage
    for (const key of Object.keys(languageMappings)) {
        if (languageMappings[key].code == lang){
            localStorage.setItem('selectedLanguage', lang)
            selectedLanguage = lang
        }
    }

    return selectedLanguage
}
