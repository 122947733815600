import PrimaryButton from "../../Components/PrimaryButton";
import { useNavigate } from "react-router-dom";


export default function MainSection({ title, description, src ,getButton}) {
    const navigate = useNavigate();

    return (
        <>
            <div className='grid border-1 gap-12 lg:gap-0 lg:grid-cols-12 grid-cols-1  '>

                <div
                    className={`w-full h-full lg:col-span-7`} >
                    <div className='flex flex-col justify-center h-full mr-16'>
                        <p className='text-black dark:text-white font-semibold mb-8 text-3xl md:text-4xl lg:text-5xl xl:text-6xl'>
                            {title}
                        </p>
                        <p className='text-gray-700 dark:text-gray-300 text-lg md:text-xl lg:text-xl'>
                            {description}
                        </p>
                        <PrimaryButton 
                        onClick={()=>navigate("/downloads")}
                        className="w-fit my-10  ">
                            {getButton}
                        </PrimaryButton>
                    </div>
                </div>

                <div
                    className={`w-full h-full lg:col-span-5 pb-32`} >
                    <div className=' rounded-3xl overflow-hidden'>
                        <div className='overflow-hidden aspect-square flex justify-center lg:justify-end'>
                            <img
                                className='rounded-xl object-contain h-full'
                                src={src}
                                loading="lazy"
                            >
                            </img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
