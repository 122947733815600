export default function Feature({ title, description, src }) {
    return (
        <li className="basis-full lg:basis-1/2 p-3">
            <div className="flex flex-col bg-white dark:bg-indigo-950 shadow-xl rounded-2xl p-8 h-full">
                <div className="flex justify-center items-center h-[320px] ">
                    <img
                        className='object-contain aspect-square max-h-[180px]'
                        src={src}
                        loading="lazy"
                    />
                </div>
                <div className='flex flex-col '>
                    <p className='text-center font-bold my-4 text-3xl  text-black dark:text-gray-50'>
                        {title} 
                    </p>
                    <p className='text-center text-md text-gray-600 dark:text-gray-200 '>
                        {description} 
                    </p>
                </div>
            </div>
        </li>
    )

}