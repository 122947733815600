import { NavLink } from 'react-router-dom';
export default function LayoutLink({ active = false, className = '', children, to, ...props }) {
    return (
        <>
            <NavLink
                {...props}
                to={to}
                className={({ isActive }) =>
                    isActive
                        ? 'pb-2 font-bold text-[#0083cb] dark:text-[#00a7df]'
                        : 'pb-2 font-normal text-gray-500 dark:text-gray-100'
                }
            >
                {children}
            </NavLink>
        </>
    );
}
