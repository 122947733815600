import Video from "../../Components/Video"
import { useEffect, useRef } from 'react';

export default function WhyView({ title, description, youtube }) {

    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 border-1">
                <div className="">
                    <div className='flex flex-col w-3/4'>
                        <p className='font-bold mb-8 text-3xl lg:text-4xl text-black dark:text-gray-50'>
                            {title}
                        </p>
                        <p className='text-md md:text-lg text-gray-600 dark:text-gray-200 '>
                            {description}
                        </p>
                    </div>
                </div>
                <div className="bg-sky-100 dark:bg-indigo-950 rounded-xl overflow-hidden flex items-center">

                    <div className=' aspect-[16/9] w-full'>
                        <Video youtube={youtube} />
                    </div>
                </div>
            </div>
        </>
    )
}