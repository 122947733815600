import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="flex justify-center items-center w-screen h-screen bg-gray-900">
      <div className=" text-gray-300 flex flex-col">
        <div className=" text-6xl flex  justify-center ">
          {error.status}
        </div>
        <div className="flex  justify-center ">
          Error: {error.statusText || error.message}
        </div>
      </div>

    </div>
  );
}