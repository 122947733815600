export default function Footer() {
    return (
        <footer className="bg-sky-100 dark:bg-[#000018]  px-12 lg:px-8">
            <div className="container max-w-6xl py-10 mx-auto">

                <div className='md:flex justify-between items-center '>
                    <p className="text-center text-sm text-gray-500 dark:text-gray-400 ">© 2023 - Bilnes Bilgi Teknolojileri A.Ş. Tüm hakları saklıdır.</p>
                    <div className=' flex justify-center items-center space-x-4 my-4 sm:my-0 '>
                        <a href="https://www.youtube.com/@BilnesBT" target='_blank'
                            className=" fill-gray-600 dark:fill-gray-300 hover:fill-red-600 dark:hover:fill-red-600  transition ease-in-out duration-300"
                        >
                            <svg
                                className="h-10 "
                                viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg" >

                                <path d="M45.1,12.8a5.5,5.5,0,0,0-3.9-3.9C37.8,8,24,8,24,8S10.2,8,6.8,8.9a5.5,5.5,0,0,0-3.9,3.9C2,16.2,2,23.4,2,23.4s0,7.2.9,10.6a5.5,5.5,0,0,0,3.9,3.9c3.4.9,17.2.9,17.2.9s13.8,0,17.2-.9A5.5,5.5,0,0,0,45.1,34c.9-3.4.9-10.6.9-10.6S46,16.2,45.1,12.8ZM19.6,30V16.8L31,23.4Z" />{" "}
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/company/bilnes-bilgi-teknolojileri-a-s/" target='_blank'
                            className=" text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-500"
                        >
                            <svg className=" h-10 fill-current"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 01-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 002 2.866v10.268a.88.88 0 00.885.866h10.226a.882.882 0 00.889-.866V2.865a.88.88 0 00-.889-.864z"
                                    />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </footer >
    )
}