import Layout from '../Layout';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Components/LanguageContext';
import handleTransformLang from '../functions/handleTransformLang'
import DownloadCard from './Download/DownloadCard';
import DownloadaPageData from '../lang/pages/download.json'
import Timer from '../Components/Timer';
export default function Downlaod() {
    return (
        <Layout>
            <InnerPage />
        </Layout>
    )
}
function InnerPage() {

    const { language } = useContext(LanguageContext);
    const [content, setContent] = useState(handleTransformLang(DownloadaPageData.download));

    useEffect(() => {
        setContent(handleTransformLang(DownloadaPageData.download))
    }, [language])
    return (
        <>
            <div className='pt-32 pb-20 min-h-screen flex items-center '>
                <section className='flex flex-col items-center justify-center px-6 lg:px-8 w-full'>
                    <div className=' mx-auto max-w-6xl container  '>
                        <div className='w-full bg-blue-500/20 dark:bg-gray-500/20  p-2 rounded-xl flex justify-center mb-8 lg:mb-0'>
                            <div className='text-center text-gray-900 dark:text-gray-50 text-lg md:text-xl'>
                                {content.timertext}
                                <Timer date={DownloadaPageData.download.releasetime} />
                            </div>
                        </div>
                        {/* <div className='grid border-1 gap-10 lg:grid-cols-12 grid-cols-1 '>

                            <div
                                className={`w-full h-full lg:col-span-7 `} >
                                <div className='flex flex-col justify-center h-full w-3/4 space-y-8 '>
                                    <p className='text-4xl font-semibold text-sky-500 dark:text-blue-500 '>
                                        {content.title}
                                    </p>
                                    <p className='font-light text-gray-700 dark:text-gray-300 text-lg md:text-xl lg:text-xl '>
                                        {content.description}
                                    </p>
                                    <DownloadCard
                                        available={content.available}
                                    />
                                </div>
                            </div>

                            <div
                                className={`w-full h-full lg:col-span-5`} >
                                <div className=' rounded-3xl overflow-hidden'>
                                    <div className='overflow-hidden  lg:aspect-[12/11] flex justify-center lg:justify-end'>
                                        <img
                                            className='rounded-xl object-contain h-full'
                                            src="/images/laptop.png"
                                            loading="lazy"
                                        >
                                        </img>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
            </div>
        </>
    );
}
