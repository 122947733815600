import { NavLink } from 'react-router-dom';
export default function NavbarLink({ active = false, className = '', children, to, ...props }) {
    return (
        <>
            <NavLink
                {...props}
                to={to}
                className={({ isActive }) =>
                    isActive
                        ? 'pb-2 border-b-4 font-bold text-[#0083cb] dark:text-[#00a7df] border-[#0083cb] dark:border-[#00a7df]'
                        : 'font-normal pb-2  text-gray-500 dark:text-gray-100'
                }
            >
                {children}
            </NavLink>
        </>
    );
}
