import Layout from '../Layout';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Components/LanguageContext';
import LangData from '../lang/pages/home.json'
import handleTransformLang from '../functions/handleTransformLang'
import MainSection from './Home/MainSection';
import WhyView from './Home/WhyView';
import Feature from './Home/Feature';
import FeatureData from '../lang/features.json';
export default function Home() {
    return (
        <Layout>
            <InnerPage />
        </Layout>
    )
}
function InnerPage() {


    const { language } = useContext(LanguageContext);
    const [main, setMain] = useState(handleTransformLang(LangData.main));
    const [why, setWhy] = useState(handleTransformLang(LangData?.why));
    const [whyHeader, setWhyHeader] = useState(handleTransformLang(LangData?.whyheader));

    const [features, setFeatures] = useState(getIterableData(FeatureData));

    function getIterableData(data) {
        return Object.values(data).map(
            (item) => handleTransformLang(item)
        )
    }

    useEffect(() => {
        setFeatures(getIterableData(FeatureData))
        setMain(handleTransformLang(LangData.main));
        setWhy(handleTransformLang(LangData?.why));
        setWhyHeader(handleTransformLang(LangData?.whyheader))
    }, [language])

    return (
        <>
            <div className='pb-20 '>
                <section className='flex flex-col items-center justify-center overflow-hidden bg-sky-200 max-h-full min-h-screen dark:bg-indigo-950 pt-32 pb-20 px-6 lg:px-8'>
                    <div className='mx-auto max-w-6xl container '>
                        <MainSection
                            title={main.title}
                            description={main.description}
                            src={main.src}
                            getButton={main.getbutton}
                        />
                    </div>
                </section>
                <section className='flex flex-col items-center justify-center bg-white dark:bg-[#000022] overflow-hidden py-12 px-6 lg:px-8'>
                    <div className=' mx-auto max-w-6xl container '>
                        <div className='flex flex-col items-center '>
                            <header className=' font-bold text-3xl lg:text-4xl text-black dark:text-gray-50 pb-6 '>
                                {whyHeader.header}
                            </header>
                            <p className='text-gray-700 dark:text-gray-300 text-lg md:text-xl lg:text-xl'>
                                {whyHeader.description}
                            </p>
                            <div className='mt-24 mb-12 w-full'>
                                <WhyView
                                    title={why?.title}
                                    description={why?.description}
             
                                    youtube={why?.youtube}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='flex flex-col items-center justify-center overflow-hidden py-12 px-6 lg:px-8'>
                    <div className=' mx-auto max-w-6xl container '>
                        <ul className='flex flex-wrap justify-center '>

                            {Object.values(features).map((feature, index) =>
                                <Feature key={index}
                                    title={feature.title} description={feature.description} src={feature.src}
                                />
                            )}

                        </ul>
                    </div>
                </section>
            </div>
        </>
    );
}
