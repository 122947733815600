import languageMappings from "../lang/languages.json"
export default function handleLangSwitch(data) {

    let selectedLanguage =localStorage?.getItem('selectedLanguage')
    let result

    for (const key of Object.keys(languageMappings)) {
        if (languageMappings[key].code == selectedLanguage){
            result = data[languageMappings[key].code]
        }
    }

    let link

    if (data.link !== undefined) {
        link = data.link
    }

    if (result == undefined) {
        return { ...data.tr, link }
    }

    return { ...result, link };

}
