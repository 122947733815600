import React from 'react'
import ReactDOM from 'react-dom/client'
import ErrorPage from './Pages/ErrorPage.jsx'
import './css/index.css'
import Home from './Pages/Home.jsx'
import Download from './Pages/Download.jsx'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />
    },
    {
      path: "/downloads",
      element: <Download />,
    }

  ]
);
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
