
function SvgComponent({ className }) {
  return (
    <>
      <img
        className={`inline dark:hidden  h-12 ` + className}
        src='/logo.png'
      />
      <img
        className={`hidden dark:inline  h-12 ` + className}
        src='/logodark.png'
      />

    </>
  )
}

export default SvgComponent
