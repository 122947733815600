
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { LanguageContext } from './Components/LanguageContext.jsx';
import React from 'react'
import { useLocation } from 'react-router-dom';
import Nav from './Nav'
import NavLang from './lang/pages/nav.json'
import handleTransformLang from './functions/handleTransformLang.js'

import handleLangSwitch from './functions/handleLangSwitch.js'
import Footer from './Footer.jsx';

export default function Layout({ children }) {

    let pathname = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [language, setLanguage] = useState(handleLangSwitch(localStorage.getItem('selectedLanguage')));
    const [content, setContent] = useState(handleTransformLang(NavLang));

    useEffect(() => {
        setContent(handleTransformLang(NavLang))
    }, [language])

    return (
        <LanguageContext.Provider value={{ language, setLanguage, handleLangSwitch }}>
            <Nav />
            <main className=' bg-gradient-to-bl from-gray-200 via-gray-100 to-white dark:bg-gradient-to-bl dark:bg-gradient-to-bl dark:from-[#000044] dark:via-[#000029] dark:to-[#000033]'>
                {children}
            </main>
            <Footer/>
        </LanguageContext.Provider>
    )
}
