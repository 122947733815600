import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useContext } from 'react';
import { LanguageContext } from '../Components/LanguageContext';
import languages from '../lang/languages.json'

export default function LangaugeSwitcher() {

    const { setLanguage, handleLangSwitch } = useContext(LanguageContext);
    const { language } = useContext(LanguageContext);

    return (
        <Menu as="div" className="relative inline-block text-left ">
            <div className='flex justify-center' >
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md  ">
                    <svg
                        className='stroke-2 h-7 stroke-gray-500/70 dark:stroke-gray-100/70'
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3 12h18M3 12a9 9 0 009 9m-9-9a9 9 0 019-9m9 9a9 9 0 01-9 9m9-9a9 9 0 00-9-9m0 18C4.756 13.08 8.982 5.7 12 3m0 18c7.244-7.92 3.018-15.3 0-18"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-50 mt-5 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white/70 dark:bg-indigo-900/70 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                    <div className="">
                        {
                            Object.values(languages).map((lang) => (
                                <Menu.Item key={lang.code}>
                                    <div onClick={() => setLanguage(handleLangSwitch(lang.code))}
                                        className={`flex justify-center px-4 py-2  text-gray-900 dark:text-gray-100 hover:bg-blue-300/50 dark:hover:bg-indigo-700/50 cursor-pointer ${(language==lang.code)?"bg-blue-300/50 dark:bg-indigo-700/50":"bg-gray-white/80 dark:bg-transparent"}`}>
                                        {lang.name}
                                    </div>
                                </Menu.Item>
                            ))
                    }

                    </div>
                </Menu.Items>
            </Transition >
        </Menu >
    )
}
